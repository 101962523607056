import { lazy } from "react";

export const publicRoutes = [
  {
    path: "/signin",
    exact: true,
    component: lazy(() => import("@screens/Auth/SignIn")),
  },
  {
    path: "/404",
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: "/500",
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
];

export const privateRoutes = [
  {
    name: "home",
    path: "/",
    ability: null,
    exact: true,
    component: lazy(() => import("@screens/Home")),
  },
  {
    name: "users.list",
    path: "/users/list",
    ability: "user.index",
    exact: true,
    component: lazy(() => import("@screens/User")),
  },
  {
    name: "users.create",
    path: "/users/create",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/User/create")),
  },
  {
    name: "companies.list",
    path: "/companies/list",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Company")),
  },
  {
    name: "companies.create",
    path: "/companies/create",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Company/create")),
  },
  {
    name: "companies.update",
    path: "/company/:id",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Company/update")),
  },
  {
    name: "users.update",
    path: "/user/:id",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/User/update")),
  },
  {
    name: "abilities.list",
    path: "/abilities/list",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Abilities/index")),
  },
  {
    name: "abilities.create",
    path: "/abilities/create",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Abilities/create")),
  },
  {
    name: "abilities.update",
    path: "/ability/:id",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Abilities/update")),
  },
  {
    name: "cash_conciliation",
    path: "/cash-conciliation",
    ability: "cash-conciliation",
    exact: true,
    component: lazy(() => import("@screens/CashConciliation/index")),
  },
  {
    name: "cash_conciliation.transactions",
    path: "/cash-conciliation/:id",
    ability: "cash-conciliation",
    exact: true,
    component: lazy(() => import("@screens/CashConciliation/transactions")),
  },
  {
    name: "cash_conciliation.close.checkout",
    path: "/cash-conciliation/:id/close-checkout/:solicitation?",
    ability: "cash-conciliation",
    exact: true,
    component: lazy(() => import("@screens/CashConciliation/checkout")),
  },
  {
    name: "manual-transactions",
    path: "/cash-conciliation/:id/manual-transactions/:solicitation?",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/manualTransactions")
    ),
  },
  {
    name: "diff-transactions",
    path: "/cash-conciliation/:id/diff-transactions/:solicitation?",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() => import("@screens/CashConciliation/diffTransactions")),
  },
  {
    name: "fuel-nozzle.list",
    path: "/fuel-nozzle",
    ability: "fuel-nozzle.actions",
    exact: true,
    component: lazy(() => import("@screens/FuelNozzle/index")),
  },
  {
    name: "fuel-nozzle.create",
    path: "/fuel-nozzle/create",
    ability: "fuel-nozzle.actions",
    exact: true,
    component: lazy(() => import("@screens/FuelNozzle/create")),
  },
  {
    name: "cash-conciliation-solicitation.list-conferences",
    path: "/cash-conciliation/solicitation/conferences",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/Solicitation/Conference/index")
    ),
  },
  {
    name: "cash-conciliation-solicitation.inconsistent-transactions",
    path: "/cash-conciliation/solicitation/:date/inconsistent-transactions",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() =>
      import(
        "@screens/CashConciliation/Solicitation/InconsistentTransactionModal/index"
      )
    ),
  },
  {
    name: "cash-conciliation-solicitation.checkout",
    path: "/cash-conciliation/solicitation/:date/checkout/:solicitation?",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/Solicitation/Checkout/index")
    ),
  },
  {
    name: "cash-conciliation-solicitation.pending",
    path: "/cash-conciliation/solicitation/pending",
    ability: "cash-conciliation.solicitation.analyze",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/Solicitation/Pending/index")
    ),
  },
  {
    name: "cash-conciliation-solicitation.show",
    path: "/cash-conciliation-solicitation/:id",
    ability: "cash-conciliation.solicitation",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/Solicitation/Single/index")
    ),
  },
  {
    name: "cash-conciliation.movement-reason-map",
    path: "/cash-conciliation-movement-reason-map",
    ability: "cash-conciliation",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/MovementReasonMap/index")
    ),
  },
  {
    name: "integration.events",
    path: "/integration/dominio-setup",
    ability: "dominio-integration",
    exact: true,
    component: lazy(() =>
      import("@screens/Integration/DominioAutoSystem/index")
    ),
  },
  {
    name: "integration.launch-calculation",
    path: "/integration/dominio-launch-calculation",
    ability: "dominio-integration",
    exact: true,
    component: lazy(() =>
      import("@screens/Integration/DominioAutoSystem/launchCalculation")
    ),
  },
  {
    name: "financial.borderos",
    path: "/financial/borderos",
    ability: "financial.borderos",
    exact: true,
    component: lazy(() => import("@screens/Financial/Bordero/index")),
  },
  {
    name: "financial.acquirer-transactions",
    path: "/financial/acquirer-transactions",
    ability: "financial.acquirer-transactions",
    exact: true,
    component: lazy(() => import("@screens/Financial/AcquirerTransactions/index")),
  },
  {
    name: "financial.bank.balance",
    path: "/financial/bank/balance",
    ability: "financial.bank-balances",
    exact: true,
    component: lazy(() => import("@screens/Financial/BankBalance/index")),
  },
  {
    name: "fiscal.sage-integration",
    path: "/fiscal/sage-integration",
    ability: "fiscal.sage-integration",
    exact: true,
    component: lazy(() => import("@screens/Fiscal/SageIntegration/index")),
  },
  {
    name: "smart-pos-terminal.list",
    path: "/smart-pos-terminal",
    ability: "smart-pos-terminal.actions",
    exact: true,
    component: lazy(() => import("@screens/SmartPosTerminal/index")),
  },
  {
    name: "tasks.list",
    path: "/tasks/list",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/Task/index")),
  },
  {
    name: "tasks.create",
    path: "/tasks/create",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/Task/create")),
  },
  {
    name: "tasks.update",
    path: "/tasks/:id",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/Task/update")),
  },
  {
    name: "task-groups.list",
    path: "/task-groups/list",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/TaskGroup/index")),
  },
  {
    name: "task-groups.create",
    path: "/task-groups/create",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/TaskGroup/create")),
  },
  {
    name: "task-groups.update",
    path: "/task-groups/:id",
    ability: "task-flow.tasks",
    exact: true,
    component: lazy(() => import("@screens/TaskGroup/update")),
  },
  {
    name: "my-tasks",
    path: "/my-tasks",
    ability: "task-flow.my-tasks",
    exact: true,
    component: lazy(() => import("@screens/MyTask/index")),
  },
  {
    name: "cash-conciliation.movement-type",
    path: "/movement-type",
    ability: "cash-conciliation.movement-type",
    exact: true,
    component: lazy(() =>
      import("@screens/CashConciliation/MovementType/index")
    ),
  },
  {
    name: "financial.download-transactions",
    path: "/financial/download-transactions",
    ability: "financial.download-transactions",
    exact: true,
    component: lazy(() => import("@screens/Financial/DownloadTransactions/index")),
  },
  {
    name: 'card-conciliation.list',
    path: '/card-conciliation',
    ability: 'financial.card-conciliation',
    exact: true,
    component: lazy(() => import('@screens/CardConciliation/index')),
  },
  {
    name: 'transaction-conciliation.list',
    path: '/transaction-conciliation',
    exact: true,
    component: lazy(() => import('@screens/TransactionConciliation/index')),
  },
  {
    name: "column-mapping",
    path: "/financial-transactions/setting/column-mapping",
    ability: "setting-column-mapping",
    exact: true,
    component: lazy(() => import("@screens/Financial/ColumnMapping/Mapping/index")),
  },
  {
    name: "column-mapping",
    path: "/financial-transactions/column-mapping/import",
    ability: "import-file-column-mapping",
    exact: true,
    component: lazy(() => import("@screens/Financial/ColumnMapping/Import/index")),
  },
  {
    name: "purchases-order.list",
    path: "/purchases/order/list",
    ability: "purchases.purchase-orders",
    exact: true,
    component: lazy(() => import("@screens/Purchase/PurchaseOrder/index")),
  },
  {
    name: "purchases-order.create",
    path: "/purchases/order/create",
    ability: "purchases.purchase-orders",
    exact: true,
    component: lazy(() => import("@screens/Purchase/PurchaseOrder/create")),
  },
  {
    name: "storage",
    path: "/storage",
    ability: "storage",
    exact: true,
    component: lazy(() => import("@screens/Storage/index")),
  },
  {
    name: "settlement-account",
    path: "/settlement-account",
    ability: "settlement-account",
    exact: true,
    component: lazy(() => import("@screens/SettlementAccount/index")),
  },
  {
    name: "statements",
    path: "/financial/statements",
    ability: "financial.statements",
    exact: true,
    component: lazy(() => import("@screens/Financial/Statements/index")),
  },
  {
    name: "attachments",
    path: "/attachments",
    ability: "attachment",
    exact: true,
    component: lazy(() => import("@screens/CashConciliation/Attachment/index")),
  },
  {
    name: "acquirer-company",
    path: "/acquirer-company",
    ability: "acquirer-company",
    exact: true,
    component: lazy(() => import("@screens/AcquirerCompany/index")),
  },
  {
    name: "financial.system-movements",
    path: "/financial/system-movements",
    ability: "financial.system-movements",
    exact: true,
    component: lazy(() => import("@screens/SystemMovement/SystemMovements/index")),
  },
  {
    name: "budget.list",
    path: "/budget/list",
    ability: "admin",
    exact: true,
    component: lazy(() => import("@screens/Budget")),
  },
];
